import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['projects', 'projectsLoader', 'todoLists', 'todoListsLoader', 'assignSection', 'assignToggle', 'people', 'peopleLoader', 'dueSection', 'dueToggle', 'subscribeToggle', 'subscribeSection', 'cardTableColumns', 'cardTableColumnsLoader', 'targetTypeTodoList', 'targetTypeCardTableColumn', 'todoListsContainer', 'cardTableColumnsContainer', 'subscribeContainer', 'showIfTodo', 'showIfCard']
  
  connect() {
    this.toggleAssign();
    this.toggleSubscribe();
    this.toggleDue();
    this.loadProjects();
    this.showIfTodoOrCard();
  }

  onProjectChange() {
    this.loadTodoLists();
    this.loadPeople();
    if (this.data.get('disableCardTables') !== 'true') {
      this.loadCardTableColumns();
    }
  }

  showIfTodoOrCard() {
    if (this.data.get('disableCardTables') !== 'true') {
      if (this.targetTypeTodoListTarget.checked) {
        this.showIfTodoTargets.forEach(function(element) {
          element.style.display = 'inline';
        })
        this.showIfCardTargets.forEach(function(element) {
          element.style.display = 'none';
        })
      } else {
        this.showIfTodoTargets.forEach(function(element) {
          element.style.display = 'none';
        })
        this.showIfCardTargets.forEach(function(element) {
          element.style.display = 'inline';
        })
      }
    }
  }

  toggleAssign() {
    if (this.assignToggleTarget.checked) {
      this.assignSectionTarget.style.display = 'block';
    } else {
      this.assignSectionTarget.style.display = 'none';
    }
  }

  toggleDue() {
    if (this.dueToggleTarget.checked) {
      this.dueSectionTarget.style.display = 'block';
    } else {
      this.dueSectionTarget.style.display = 'none';
    }
  }

  toggleSubscribe() {
    if (this.subscribeToggleTarget.checked) {
      this.subscribeSectionTarget.style.display = 'block';
    } else {
      this.subscribeSectionTarget.style.display = 'none';
    }
  }

  refreshProjects(element) {
    element.preventDefault()
    this.loadProjects()
  }

  refreshTodoLists(element) {
    element.preventDefault()
    this.loadTodoLists()
  }

  refreshCardTableColumns(element) {
    element.preventDefault()
    this.loadCardTableColumns()
  }

  loadProjects() {
    this.projectsLoaderTarget.style.display = 'block';
    this.projectsTarget.style.display = 'none';
    this.todoListsTarget.style.display = 'none';
    this.todoListsLoaderTarget.style.display = 'block';

    this.projectsTarget.options.length = 0;

    fetch(this.data.get('accountPath') + '/basecamp_projects.json', {
      method: 'GET',
      credentials: 'same-origin'
    }).then((response) => {
      return response.json();
    }).then((response) => {
      for(var index in response) {
        this.projectsTarget.options[this.projectsTarget.options.length] = new Option(response[index][0], response[index][1] + ":" + response[index][0]);
        if (this.data.get("projectId") == response[index][1]) {
          this.projectsTarget.selectedIndex = this.projectsTarget.options.length - 1
        }
      }

      this.projectsTarget.style.display = 'block';
      this.projectsLoaderTarget.style.display = 'none';
      this.loadTodoLists();
      this.loadPeople();

      if (this.data.get('disableCardTables') !== 'true') {
        this.showTodoListsOrCardTables();
        this.loadCardTableColumns();
      }
    })
  } 

  showTodoListsOrCardTables() {
    if (this.targetTypeTodoListTarget.checked) {
      this.todoListsContainerTarget.style.display = 'block';
      this.cardTableColumnsContainerTarget.style.display = 'none';
      this.subscribeContainerTarget.style.display = "block";
      this.todoListsTarget.required = 'true';
      this.cardTableColumnsTarget.required = 'false';
    } else {
      this.cardTableColumnsContainerTarget.style.display = 'block';
      this.todoListsContainerTarget.style.display = 'none';
      this.subscribeContainerTarget.style.display = "none";
      this.cardTableColumnsTarget.required = 'true';
      this.todoListsTarget.required = 'false';
    }
    this.showIfTodoOrCard();
  }

  loadTodoLists() { 
    this.todoListsTarget.style.display = 'none';
    this.todoListsLoaderTarget.style.display = 'block';
    this.todoListsTarget.options.length = 0;

    fetch(this.data.get('accountPath') + '/basecamp_todo_lists.json?project_id=' + this.selectedProjectId(), {
      method: 'GET',
      credentials: 'same-origin'
    }).then((response) => {
      return response.json();
    }).then((response) => {

      if(this.data.get('hideAllListsOption') !== 'true') {
        this.todoListsTarget.options[this.todoListsTarget.options.length] = new Option('All todo lists in this project', 'all');
      }
      
      for(var index in response) {
        this.todoListsTarget.options[this.todoListsTarget.options.length] = new Option(response[index][0], response[index][1] + ":" + response[index][0]);
        if (this.data.get("todoListId") == response[index][1]) {
          this.todoListsTarget.selectedIndex = this.todoListsTarget.options.length - 1
        }
      }

      this.todoListsTarget.style.display = 'block';
      this.todoListsLoaderTarget.style.display = 'none';
    })
  }

  loadCardTableColumns() { 
    this.cardTableColumnsTarget.style.display = 'none';
    this.cardTableColumnsLoaderTarget.style.display = 'block';
    this.cardTableColumnsTarget.options.length = 0;

    fetch(this.data.get('accountPath') + '/basecamp_card_table_columns.json?project_id=' + this.selectedProjectId(), {
      method: 'GET',
      credentials: 'same-origin'
    })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      for(var index in response) {
        this.cardTableColumnsTarget.options[this.cardTableColumnsTarget.options.length] = new Option(response[index][0], response[index][1] + ":" + response[index][0]);
        if (this.data.get("cardTableColumnId") == response[index][1]) {
          this.cardTableColumnsTarget.selectedIndex = this.cardTableColumnsTarget.options.length - 1
        }
      }
      this.cardTableColumnsTarget.style.display = 'block';
      this.cardTableColumnsLoaderTarget.style.display = 'none';
    })
  }

  loadPeople() {
    this.peopleTargets.forEach(function(element) {
      element.style.display = 'none';
      element.options.length = 0;
    })
    
    this.peopleLoaderTargets.forEach(function(element) {
      element.style.display = 'block';
    })

    fetch(this.data.get('accountPath') + '/basecamp_people.json?project_id=' + this.selectedProjectId(), {
      method: 'GET',
      credentials: 'same-origin'
    }).then((response) => {
      return response.json();
    }).then((response) => {
      for(var index in response) {
        this.peopleTargets.forEach(function(element) {
          element.options[element.options.length] = new Option(response[index][0], response[index][1] + ":" + response[index][0]);
          if (element.dataset.peopleIds && element.dataset.peopleIds.includes(response[index][1])) {
            element.options[element.options.length - 1].selected = true
          }
          element.style.display = 'block';
        })
      }
      this.peopleLoaderTargets.forEach(function(element) {
        element.style.display = 'none';
      })
    })
  } 

  selectedProjectId() {
    return this.projectsTarget.options[this.projectsTarget.selectedIndex].value.split(':')[0];
  }
}
